@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";
@import "@/styles/base/_fonts";
$header-height: 115px;

@mixin nav-filter {
  filter: invert(100) sepia(100%) saturate(0) hue-rotate(346deg)
    brightness(161%) contrast(125%);
}

footer
  .gbh-footer-main-nav
  .gbh-footer-main-nav-link
  .footer-inner-head.gbh-expand
  .accordion
  .line.line-2 {
  height: 0;
}

.header-container {
  z-index: 1001;
}

.hero-banner-container {
  .cmp-container {
    .hero-banner-des-medium-top {
      .cmp-teaser {
        &__content {
          width: auto;
          @include breakpoint(tab-max) {
            width: 100%;
          }
        }
      }
    }
  }
}

.teaser {
  &.dot-whack-teaser-small {
    &.teaser-pos-right-top {
      &.image-right {
        .cmp-teaser {
          &__content {
            @include breakpoint(tab-max) {
              width: 50.24% !important;
            }
            @include breakpoint(mobile-potrait) {
              margin-right: 1.5rem;
              width: 57.6% !important;
            }
          }
        }
      }
    }
  }
}
.teaser {
  &.dot-whack-teaser-without-img {
    .cmp-teaser {
      @media screen and (max-width: 1037px) {
        &__content {
          max-width: unset;
          position: relative !important;
          padding: 30px 3.94%;
          justify-content: flex-start;
          width: auto;
          margin-top: 9px;
        }
        &__title {
          width: 45.5%;
        }
        &__description {
          width: 45.5%;
        }
        &__action-container {
          width: 45.5%;
          position: absolute;
        }
      }
      @include breakpoint(mobile-landscape) {
        &__content {
          margin-top: 9px;
          padding-top: 50px;
        }
      }
      @include breakpoint(mobile) {
        &__content {
          margin-top: 9px;
        }
        &__title {
          width: 100%;
        }
        &__description {
          width: 100%;
        }
        &__action-container {
          width: 100%;
          position: relative;
        }
      }
    }
  }
}
.teaser {
  &.dot-whack-teaser-without-img {
    &.teaser-pos-right-top {
      @media screen and (max-width: 1037px) {
        margin-top: 0px;
        position: relative;
        top: 0px;
      }
    }
  }
}

.scroll-lock {
  .product-detail-page__left-sticky,
  .product-detail-page__right-sticky {
    position: relative !important;
  }
  .product-detail-page__left-sticky,
  .product-detail-page__right-sticky {
    position: relative !important;
  }
}

.gbh-header-logo-menu {
  z-index: 1000;
}

.cmp-tabs__tabpanel--active {
  animation: none;
}
@media (max-width: 1200px) {
  .cmp-tabs__tabpanel.cmp-tabs__tabpanel--active
    .gbh-product-menu.open
    .gbh-pro-menu__list {
    -webkit-animation: none;
  }
}

@media screen and (min-width: 1201px) {
  .auth-widget .auth-nav .auth-nav__menu:after {
    pointer-events: none;
  }
}

.nav-up .brand-logo img,
.nav-up .cart-indicator-widget .cart-indicator img,
.nav-up .icon-Hamburger:before {
  filter: invert(0) sepia(100%) saturate(0) hue-rotate(346deg) brightness(161%)
    contrast(125%);
}

.nav-up .wishlist-widget img {
  filter: none;
}
.mavenoid-remove {
  z-index: -1 !important;
}
.in-page-search {
  z-index: 0;
}
.cart-indicator-widget {
  &__checkout {
    .cart-indicator {
      span {
        color: $color-white;
      }
    }
  }
}

@media (max-width: 639px) {
  .double-featured-content-cards {
    padding: 3.125rem 4.8% 1.875rem;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .container.responsivegrid .cmp-container .gbh-align-item-center {
    padding: 50px 36px;
  }
}
@media (max-width: 1023px) {
  .container.responsivegrid .cmp-container .gbh-align-item-center {
    padding: 50px 2pc;
  }
}

.container-bgcolor-black
  .cmp-teaser__action-container
  .cmp-teaser__action-link.gbh-primary-button {
  background: $white;
  font-family: Helvetica Now Text W05 Medium;
  text-align: center;
  color: #494949;
  border: 1px solid $white;
  box-sizing: border-box;
  padding: 17px 60px 19px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.35px;
}

.container-bgcolor-black
  .cmp-teaser__action-container
  .cmp-teaser__action-link.gbh-secondary-button {
  background: 0 0;
  font-family: Helvetica Now Text W05 Medium;
  text-align: center;
  color: $white;
  border: 1px solid $white;
  box-sizing: border-box;
  padding: 17px 60px 19px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.35px;
}

@media (max-width: 1200px) {
  .gbh-header-logo-menu .cmp-container .gbh-hamber-menu {
    display: block;
    margin-right: 20px;
    margin-top: 6px;
  }
}

.Plp-Search .search-result .search-result__breadcrumb.kf-react-plp-container {
  padding-top: 30px;
  margin-top: 0px;
}

.modal-backdrop.show {
  opacity: 0.5;
  z-index: 3;
}

.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .slick-active
  .cmp-carousel-slick__item
  .cmp-teaser__content,
.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .slick-current
  .cmp-carousel-slick__item
  .cmp-teaser__content {
  visibility: visible;
}

.carousel-text-left-overlap
  .cmp-carousel-slick
  .cmp-carousel-slick__content.single-items
  .cmp-carousel-slick__item {
  margin: 0;
  > div {
    margin: 0 2.5px;
  }
}

.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .cmp-carousel-slick__item {
  margin: 0;
  > div {
    margin: 0 2.5px;
  }
}

@media (max-width: 1023px) {
  .carousel-text-left-overlap
    .cmp-carousel-slick
    .cmp-carousel-slick__content
    .slick-list
    .slick-track
    .cmp-carousel-slick__item {
    margin: 0;
    > div {
      margin: 0 2px;
    }
  }
  .featured-content-carousel
    .cmp-carousel-slick
    .cmp-carousel-slick__content
    .slick-list
    .slick-track
    .cmp-carousel-slick__item {
    margin: 0;
    > div {
      margin: 0 2px;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1600px) {
  html[page="plp"],
  html[page="press-release"],
  html[page="press-kit"] {
    font-size: 15px;
  }
  html[page="support:help and faq"] {
    .kf-react-plp-container {
      @media (max-width: 1920px) {
        max-width: 94.8%;
        margin-left: 2.6%;
        margin-right: 2.6%;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  html[page="support:help and faq"] {
    .kf-react-plp-container {
      @media (max-width: 480px) {
        max-width: 90.4%;
        margin-left: 4.8%;
        margin-right: 4.8%;
      }
    }
  }
}

.searchbar-open {
  &.scroll-lock {
    position: fixed;
  }
}
.search-result__search-field {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%; // For mozilla firefox
  width: -webkit-fill-available; // For google chrome
}

@media screen and (max-width: 576px) {
  .account-settings
    .account-tabs__content
    .account-address
    .account-address__tiles
    .account-address-tile {
    grid-template-columns: 3fr 1fr 1fr;
  }
  .hideMobile {
    display: none;
  }
}

.account-settings .account-address__modal .checkbox input:checked ~ span {
  color: #757575;
}

.remove-scroll {
  overflow-y: hidden !important;
  @media screen and (min-width: 1201px) {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.pdp-sticky-header {
  .pdp-sticky-header__container {
    .pdp-sticky-header__right {
      .button {
        &:hover {
          background-color: $primary-blue;
        }
      }
    }
  }
}
.previewmode {
  border: 2px solid yellow;
}
.topcorner {
  background-color: yellow;
  color: red;
  position: fixed;
  top: 0px;
  left: 0%;
  z-index: 99999999;
  padding: 5px;
  padding-top: 10px;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
  img:hover {
    cursor: pointer;
  }
}
.previewpage {
  height: 100vh;
}

.account-address__modal .checkbox {
  margin-bottom: 27px !important;
  margin-top: 5px !important;
  align-items: center !important;
}

.contact-us-main .input-field {
  position: relative;
  margin-bottom: 20px;
}

.contact-us-main .tab-list .tab-list-active {
  color: #000;
  border-bottom: 2px solid #000;
  font-weight: 700;
}

.contact-us-main .tab-list .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 9pt 0;
  font-size: 9pt;
  color: #757575;
  margin: 0 10px;
  cursor: pointer;
}

.favorites .sort--open .sort__item {
  font-size: 18px;
}

.favorites .sort--open .sort__item.active {
  background-color: $primary-blue;
  color: $color-white;
}

@media screen and (max-width: 576px) {
  .favorites-modal .modal-popup-content {
    margin: auto 18px !important;
    min-height: 57vh;
  }
  .favorites-modal .modal-popup .modal-popup-content .modal-popup-body {
    padding: 50px 0 10px !important;
  }
  .favorites-modal .favorites__modal-cta .kf-react-button button:first-child {
    margin-bottom: 20px;
  }
}

.bg-charcoal .cmp-carousel.cmp-carousel-slick {
  + .slider__counter .total-count {
    color: #757575;
  }
}

.kf-react-button {
  &.checkout-btn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .edit-address__title
    + .address-form.container.px-0.mx-0
    div.row:last-of-type
    .input-field:last-child {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 360px) {
  .add-address__title {
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}

.add-address__title {
  margin-top: 21px;
  margin-bottom: 15px;
  font-family: "Helvetica Now Text W05 Light", sans-serif;
  font-size: 36px;
  line-height: 1.22;
  letter-spacing: -1.7px;
  color: #494949;
}
.gbh-play-video.active .playinside:before {
  content: "\e933" !important;
}
@media (max-width: 639px) {
  .cmp-container .container.responsivegrid.gbh-align-item-center {
    padding: 50px 21.33%;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .cmp-container .container.responsivegrid.gbh-align-item-center {
    padding: 70px 36px;
  }
}

:target {
  scroll-margin-top: $header-height;
}

html,
body {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

.server-error-page .cmp-image__image {
  height: auto;
}

.transparent-menu-black {
  .teaser {
    &.fc-white {
      margin-top: 0px !important;
    }
  }
}

.cmp-carousel.cmp-carousel-slick {
  width: 100%;
}

.cmp-carousel.cmp-carousel-slick + .hide {
  visibility: hidden;
}

.press-release .sort--open .sort__item {
  font-size: 18px;
  text-align: left;
}

.press-release .sort--open .sort__item.active {
  background-color: $primary-blue;
  color: $color-white;
}

.download-spinner::after {
  content: "ing....";
}

.cmp-teaser__icons {
  .share-cmp {
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    left: unset;
    transform: none;
    .social-share__box {
      position: relative;
    }
  }
}

@media (min-width: 1025px) {
  .link-tool-box {
    .cmp-teaser {
      padding: 0 3.75rem !important;
      display: flex;
    }
  }
}

.link-tool-box:first-child .cmp-teaser {
  padding-left: 0 !important;
}
.link-tool-box:last-child .cmp-teaser {
  padding-right: 0 !important;
}
.unsetPageScrollMargin {
  :target {
    scroll-margin-top: unset !important;
  }
}

@media print {
  html[page="literature"] {
    .breadcrumb,
    .kf-page-title__total,
    .cmp-teaser__content,
    .cmp-teaser__image {
      display: none;
    }
  }
}

.container-inspired-banner-with-image-text-left
  .view-more-products-container
  .inspired-selling-module__card-container {
  width: 100%;
  position: relative;
}

div .cmp-container .teaser.img-left-caption-right .cmp-teaser__content {
  position: inherit;
}
div .cmp-container .teaser.img-left-caption-right .cmp-teaser__icons {
  left: -92px;
  right: unset;
}
@media (max-width: 639px) {
  div .cmp-container .teaser.img-left-caption-right .cmp-teaser__icons {
    bottom: -100px;
    top: unset;
    right: 32px;
    left: unset;
  }
}

.brand-switcher .gbh-nav__list .gbh-nav__item .gbh-nav--link:hover {
  cursor: pointer;
}

.bg-charcoal {
  &.container-inspired-banner-with-image-text-right,
  &.inspired-banner-with-large-image-text-right,
  &.inspired-banner-with-two-images-text-right {
    .cmp-container {
      .shop-the-room-detail-container {
        .text-images-container {
          .shop-the-room-detail-text-container {
            .shop-the-room-detail-textbox {
              .ctas-box {
                a {
                  color: $white;
                  border: 1px solid $white;
                }
              }
              .view-more-products-button {
                .view-more-button-text {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
.inspired-banner-with-two-images-text-right,
.inspired-banner-with-two-images-text-left,
.container-inspired-banner-with-image-text-right,
.inspired-banner-with-large-image-text-right,
.inspired-banner-with-large-image-text-left {
  .view-more-products-container {
    .inspired-selling-module__card-container {
      width: 100%;
    }
  }
}

.container-room-detail-landscape {
  .cmp-container {
    .room-detail-parent-container {
      .room-detail-product-detail {
        .products-container {
          .v3_productCardWrapper__FIxaK {
            width: 20.29%;
            @media screen and (min-width: 640px) and (max-width: 1023px) {
              width: 20.29%;
            }
            @media screen and (max-width: 639px) {
              width: 45.402%;
            }
          }
        }
      }
    }
  }
}
.container-inspired-banner-with-image-text-right,
.container-inspired-banner-with-image-text-left {
  .cmp-container {
    .shop-the-room-detail-container {
      .text-images-container {
        .shop-the-room-detail-image-container {
          .first-image-container,
          .second-image-container {
            @media screen and (min-width: 1500px) and (max-width: 1540px) {
              width: 49%;
            }
          }
        }
      }
    }
  }
}
.inspired-banner-with-two-images-text-left,
.inspired-banner-with-two-images-text-right {
  .cmp-container {
    .shop-the-room-detail-container {
      .text-images-container {
        .shop-the-room-detail-image-container {
          .second-image-container {
            @media screen and (min-width: 1500px) and (max-width: 1540px) {
              width: 54.8%;
            }
          }
        }
      }
    }
  }
}
.inspired-banner-with-large-image-text-left,
.inspired-banner-with-large-image-text-right {
  .cmp-container {
    .shop-the-room-detail-container {
      .text-images-container {
        .shop-the-room-detail-text-container {
          @media screen and (min-width: 1500px) and (max-width: 1540px) {
            width: 78%;
          }
        }
      }
    }
  }
}
.container-inspired-banner-with-image-text-left,
.container-inspired-banner-with-image-text-right,
.inspired-banner-with-two-images-text-left,
.inspired-banner-with-two-images-text-right,
.inspired-banner-with-large-image-text-left,
.inspired-banner-with-large-image-text-right {
  .cmp-container {
    .shop-the-room-detail-container {
      .text-images-container {
        .shop-the-room-detail-text-container {
          .shop-the-room-detail-textbox {
            .view-more-products-button {
              .view-more-button-text {
                @media screen and (max-width: 1037px) {
                  margin-left: 0;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
.bg-charcoal {
  &.container-inspired-banner-with-image-text-right,
  &.container-inspired-banner-with-image-text-left,
  &.inspired-banner-with-large-image-text-right,
  &.inspired-banner-with-large-image-text-left,
  &.inspired-banner-with-two-images-text-right,
  &.inspired-banner-with-two-images-text-left {
    .cmp-container {
      .shop-the-room-detail-container {
        .text-images-container {
          .shop-the-room-detail-text-container {
            .shop-the-room-detail-textbox {
              .view-more-products-button {
                .view-more-button-text {
                  @media screen and (max-width: 1037px) {
                    margin-left: 0;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        .view-more-products-container {
          .card-details__card-title-container,
          .card-details__card-price {
            p {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.video {
  &.video-right,
  &.video-left {
    &.history-module-with-video {
      .cmp-teaser {
        .cmp-teaser__image {
          .cmp-img-caption {
            z-index: 2;
            position: relative;
          }
        }
      }
    }
  }
}
.plppage {
  .cmp-teaser {
    &__content {
      z-index: 1;
    }
  }
}

.carousel-bottom-scroll,
.carousel-btm-scroll-title-small {
  .cmp-carousel__item {
    .product-card__img-container {
      background-color: #f4f4f4;
    }
  }
}
.teaser {
  &.hero-banner-des-medium-center {
    .cmp-teaser__content {
      .cmp-teaser__icons {
        top: 4.688rem;
        right: 3.75rem;
      }
    }
  }
}
.teaser-medium-3 {
  .cmp-teaser {
    position: relative;
  }
}
.container-2up-50-50 {
  .cmp-container {
    .two-up-50 {
      .cmp-teaser {
        position: relative;
      }
    }
  }
}
.double-featured-content-cards {
  .carousel-slick {
    .hide {
      display: none;
    }
  }
}
.container {
  .container-inspired-selling-carousel {
    .cmp-container {
      .inspired-theme-carousel {
        .slick-prev,
        .slick-next {
          @media screen and (max-width: 1038px) {
            top: calc(50% - 25px) !important;
          }
        }
        .slick-next {
          @media screen and (min-width: 1601px) {
            right: 20.7rem;
          }
          @media screen and (min-width: 2560px) {
            right: 51.2rem;
          }
          @media screen and (min-width: 1040px) and (max-width: 1600px) {
            right: 20.2%;
          }
          @media screen and (max-width: 1038px) {
            right: 4%;
          }
          @media screen and (max-width: 639px) {
            right: 18px;
          }
        }
        .cmp-carousel-slick {
          &__item {
            &.slick-current,
            &.slick-active {
              @media screen and (max-width: 639px) {
                width: auto !important;
              }
            }
          }
        }
      }
    }
  }
}
.container-wrapper {
  .cmp-container {
    .featured-content-carousel {
      .cmp-carousel-slick {
        .cmp-carousel-slick__content {
          .slick-list {
            @media screen and (max-width: 639px) {
              margin: 0px 8% 0 0;
            }
          }
        }
      }
    }
  }
}
.carousel-slick {
  &.inspired-theme-carousel {
    .cmp-carousel-slick {
      &__content {
        .slick-arrow {
          opacity: 1;
        }
      }
    }
  }
}
.carousel-btm-scroll-title-small {
  .teaser-small-1 {
    &.teaser-style-1 {
      .cmp-teaser {
        &__image {
          @media screen and (max-width: 1023px) {
            height: 275px;
          }
          .cmp-image {
            &__image {
              @media screen and (max-width: 1023px) {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.short-hero {
  .cmp-container {
    .carousel-3-4 {
      float: left;
      clear: none;
      width: 100%;
    }
  }
}

/*-------------------Enhanced Basic Text Block-----------------------*/
.copy-rich-basic-text {
  padding-top: 70px;
  @media screen and (max-width: 1023px) {
    padding-top: 50px;
  }
  .cmp-container {
    max-width: 20in;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 2.6%;
    @include breakpoint(desktop-large) {
      padding: 0 50px;
    }
    @include breakpoint(desktop-lg) {
      padding: 0 36px;
    }
    @include breakpoint(tab-landscape) {
      padding: 0 3.942%;
    }
    @media screen and (max-width: 639px) {
      padding: 0 18px;
    }
    .cmp-teaser {
      &__pretitle {
        padding-bottom: 40px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote {
          font-family: $helvetica-regular;
          font-size: 3.75rem;
          line-height: 4.25rem;
          letter-spacing: -0.1938rem;
          color: $color-slate;
          @include breakpoint(desktop-lg) {
            font-size: 45px;
            line-height: 56px;
            letter-spacing: -3.187px;
          }
          @include breakpoint(tab-landscape) {
            font-size: 2.5rem;
            line-height: 2.875rem;
            letter-spacing: -0.1563rem;
          }
        }
      }
      &__title {
        padding-top: 0;
        padding-bottom: 40px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote {
          font-family: $helvetica-regular;
          font-size: 1.75rem;
          line-height: 2.25rem;
          letter-spacing: -0.0875rem;
          color: $color-slate;
          @include breakpoint(desktop-lg) {
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -1.4px;
          }
          @include breakpoint(tab-landscape) {
            font-size: 1.625rem;
            line-height: 2.125rem;
            letter-spacing: -0.0938rem;
          }
        }
      }
      &__description {
        padding-top: 0;
        padding-bottom: 70px;
        @include breakpoint(tab-landscape) {
          padding-bottom: 50px;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul li,
        ol li,
        span,
        blockquote {
          font-size: 1.25rem;
          font-family: $helvetica-regular;
          line-height: 1.75rem;
          letter-spacing: -0.0406rem;
          color: $color-dark-gray;
          @include breakpoint(desktop-lg) {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.65px;
          }
          @include breakpoint(tab-landscape) {
            font-size: 1.25rem;
          }
        }
        ol,
        ul {
          margin-bottom: 0;
        }
        p {
          margin-top: 25px;
          margin-bottom: 25px;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .more {
          letter-spacing: -0.025rem;
          text-decoration: underline;
          display: inline-block;
          cursor: pointer;
          margin-top: 20px;
          color: $color-slate;
          @include breakpoint(desktop-lg) {
            letter-spacing: -0.4px;
          }
        }
      }
    }
    .faq-accordion {
      width: 100%;
      padding-bottom: 70px;
      @include breakpoint(tab-landscape) {
        padding-bottom: 50px;
      }
      .cmp-accordion {
        .text {
          .cmp-text {
            padding-top: 25px;
            @include breakpoint(tab-landscape) {
              padding-top: 20px;
            }
            @media screen and (max-width: 376px) {
              padding-top: 25px;
            }
            p,
            blockquote {
              padding-bottom: 0;
              padding-top: 0px;
              color: $color-dark-gray;
            }
          }
        }
        &__item {
          padding: 40px 0;
          border-top: 1px solid $color-dark-gray;
          @media only screen and (min-width: 640px) and (max-width: 1023px) {
            padding: 20px 0;
          }
          .faq-text {
            .cmp-text {
              ul,
              ol {
                margin-bottom: 0px;
                li {
                  color: $color-dark-gray;
                  padding-bottom: 5px;
                }
              }
              blockquote {
                color: $color-dark-gray;
              }
            }
          }
        }
        &__header {
          padding: 0;
          .cmp-accordion__title {
            font-size: 1.75rem;
            line-height: 2.25rem;
            letter-spacing: -0.0875rem;
            @include breakpoint(desktop-lg) {
              font-size: 28px;
              line-height: 36px;
              letter-spacing: -1.4px;
            }
            @media only screen and (min-width: 640px) and (max-width: 1023px) {
              font-size: 1.625rem;
              line-height: 2.125rem;
              letter-spacing: -0.0938rem;
            }
          }
          .cmp-accordion__icon {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 2px;
            right: 0;
            &::before {
              content: none;
              width: 30px;
              height: 30px;
              @include breakpoint(tab-landscape) {
                top: 30px;
                font-size: 30px;
              }
            }
            .line {
              background: $color-dark-gray;
              transition: all 0.5s ease;
              &.line-1 {
                width: 30px;
                height: 1px;
              }
              &.line-2 {
                -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
                width: 1px;
                height: 30px;
              }
            }
          }
          .cmp-accordion__button {
            padding: 0 62px 0 0;
            @include breakpoint(tab-landscape) {
              padding: 0 62px 0 0;
            }
            @media only screen and (min-width: 640px) and (max-width: 812px) {
              padding: 0 120px 0 0;
            }
          }
          .cmp-accordion__button--expanded {
            .cmp-accordion__icon {
              &::before {
                content: none;
                width: 30px;
                height: 30px;
                @include breakpoint(tab-landscape) {
                  top: 44px;
                  font-size: 1px;
                }
              }
              .line-2 {
                height: 0;
              }
            }
          }
        }
      }
    }
  }
}
.bg-charcoal {
  &.copy-rich-basic-text {
    .cmp-container {
      .teaser {
        .cmp-teaser {
          .cmp-teaser__pretitle {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            blockquote {
              color: $color-white;
            }
          }
          &__description {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            a,
            b,
            p a,
            p b,
            ul li,
            ol li,
            span,
            blockquote {
              color: $color-light-gray;
            }
            .more {
              color: $color-white;
            }
          }
        }
      }
      .faq-accordion {
        .cmp-accordion {
          &__item {
            border-top: 1px solid $color-light-gray;
          }
          .text {
            .cmp-text {
              p,
              a,
              blockquote {
                color: $color-light-gray;
              }
            }
          }
          .faq-text {
            .cmp-text {
              ul,
              ol {
                li {
                  color: $color-light-gray;
                }
              }
              blockquote {
                color: $color-light-gray;
              }
            }
          }
        }
      }
    }
  }
}
.bg-off-white {
  &.copy-rich-basic-text {
    .cmp-container {
      .teaser {
        .cmp-teaser__description {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          a,
          b,
          p a,
          p b,
          ul li,
          ol li,
          span,
          blockquote {
            color: $color-dark-gray;
          }
          .more {
            color: $color-slate;
          }
        }
      }
    }
  }
}
.bg-white {
  &.copy-rich-basic-text {
    .cmp-container {
      .teaser {
        .cmp-teaser__pretitle {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          ul li,
          ol li,
          span,
          blockquote {
            color: $color-slate;
          }
        }
        .cmp-teaser__description {
          .more {
            color: $color-slate;
          }
        }
      }
    }
  }
}

.container-shop-the-room {
  .cmp-container {
    .shoptheroomcard {
      display: inline-block;
      @include breakpoint(tab-landscape) {
        width: 47.326%;
        margin-right: 0;
      }
      @include breakpoint(mobile-potrait) {
        display: block;
        width: 100%;
      }
      &:nth-child(2n + 2) {
        @include breakpoint(tab-landscape) {
          margin-left: 5.348%;
          margin-right: 0;
        }
        @include breakpoint(mobile-potrait) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}
.room-inspiration-carousel {
  .cmp-carousel-slick {
    &__content {
      &:hover,
      &:focus,
      &:active {
        .slick-arrow {
          background: none;
          border: none;
          outline: none;
        }
      }
      .slick-arrow {
        background: none;
        border: none;
        outline: none;
      }
    }
  }
}
.container {
  &.justify-align-space {
    padding: 70px 0;
    @media screen and (min-width: 1024px) and (max-width: 1380px) {
      padding: 70px 60px;
    }
    @media screen and (max-width: 1024px) {
      padding: 50px 3.94%;
    }
    @media screen and (max-width: 639px) {
      padding: 50px 4.8%;
    }
  }
}
.carousel-bottom-cta-right {
  &.breadcrums-withcarosel {
    .cmp-carousel {
      .cmp-carousel__content {
        .cmp-carousel__actions {
          .cmp-carousel__action {
            &.cmp-carousel__action--next {
              @include breakpoint(tab-max) {
                bottom: 85px;
              }
              @media screen and (max-width: 639px) {
                bottom: 317px;
              }
              .cmp-carousel__action-text {
                @media screen and (max-width: 639px) {
                  right: 4.8%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.carousel-bottom-cta-right {
  .cmp-carousel {
    .cmp-carousel__content {
      .cmp-carousel__actions {
        .cmp-carousel__action {
          &.cmp-carousel__action--next {
            @include breakpoint(tab-max) {
              bottom: 85px;
            }
            @media screen and (max-width: 639px) {
              bottom: 317px;
            }
            .cmp-carousel__action-text {
              @media screen and (max-width: 639px) {
                right: 4.8%;
              }
            }
          }
          &--pause,
          &--play {
            @media screen and (min-width: 992px) {
              background: transparent;
              color: $color-light-gray;
              box-shadow: none;
              border: 0;
            }
          }
        }
        z-index: 4;
      }
    }
  }
}

.container-inspired-banner-with-image-text-right,
.container-inspired-banner-with-image-text-left,
.inspired-banner-with-large-image-text-right,
.inspired-banner-with-large-image-text-left,
.inspired-banner-with-two-images-text-right,
.inspired-banner-with-two-images-text-left .cmp-container {
  .shop-the-room-detail-container {
    .text-images-container {
      .shop-the-room-detail-text-container {
        .shop-the-room-detail-textbox {
          .card-title {
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            a,
            sub,
            sup {
              padding: 0;
              font-size: 2.8125rem;
              line-height: 3.5rem;
              letter-spacing: -0.1994rem;
              font-family: $font-light;
              margin: 0;
              @media screen and (max-width: 1023px) {
                font-size: 28px;
                line-height: 36px;
                letter-spacing: -1.98px;
              }
            }
          }
        }
      }
    }
  }
}

.container-inspired-banner-with-image-text-right,
.container-inspired-banner-with-image-text-left,
.inspired-banner-with-large-image-text-right,
.inspired-banner-with-large-image-text-left,
.inspired-banner-with-two-images-text-right,
.inspired-banner-with-two-images-text-left .cmp-container {
  .shop-the-room-detail-container {
    .text-images-container {
      .shop-the-room-detail-text-container {
        .shop-the-room-detail-textbox {
          .card-description {
            p {
              margin-top: 40px;
              padding: 0;
              font-size: 1.25rem;
              line-height: 1.75rem;
              letter-spacing: -0.0406rem;
              font-family: $font-regular;
              a {
                text-decoration: underline;
              }
            }
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
.bg-off-white {
  .cmp-container {
    .container-inspired-banner-with-image-text-right,
    .container-inspired-banner-with-image-text-left,
    .inspired-banner-with-large-image-text-right,
    .inspired-banner-with-large-image-text-left,
    .inspired-banner-with-two-images-text-right,
    .inspired-banner-with-two-images-text-left .cmp-container {
      .shop-the-room-detail-container {
        .text-images-container {
          .shop-the-room-detail-text-container {
            .shop-the-room-detail-textbox {
              .card-description {
                p {
                  color: $color-light-gray;
                  a {
                    color: $color-light-gray;
                  }
                }
                ul li,
                ol,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                sub,
                blockquote,
                span,
                a {
                  color: $color-light-gray;
                }
                ol li {
                  color: $color-light-gray;
                  display: list-item;
                  @media screen and (min-width: 1023px) {
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.teaser {
  &.bg-charcoal {
    &.img-bg-light-grey {
      .cmp-teaser__action-container {
        .cmp-teaser__action-link {
          color: $color-white !important;
        }
      }
    }
  }
}
.short-hero {
  .cmp-carousel {
    .cmp-carousel__content {
      .cmp-carousel__actions {
        .cmp-carousel__action {
          @media screen and (min-width: 992px) {
            height: auto;
          }
        }
      }
    }
  }
}
.short-hero {
  .carousel-bottom-cta-right {
    .cmp-carousel {
      .cmp-carousel__content {
        .cmp-carousel__actions {
          .cmp-carousel__action {
            &.cmp-carousel__action--next {
              @include breakpoint(desktop-small) {
                bottom: 85px !important;
              }
              @media screen and (max-width: 639px) {
                bottom: 130px !important;
              }
            }
          }
        }
      }
    }
  }
}

.article-text-style-1 {
  &.article-text-display-style-1 {
    .cmp-text {
      ul {
        &:last-child {
          padding-bottom: 70px;
          margin-bottom: 0;
          @include breakpoint(tab-max) {
            padding-bottom: 50px;
          }
        }
      }
    }
  }
}
.bg-charcoal {
  .cmp-container {
    .article-text-style-1 {
      &.article-text-display-style-1 {
        .cmp-text {
          p {
            b {
              color: $color-light-gray;
            }
          }
        }
      }
    }
  }
}
.bg-white {
  .cmp-container {
    .text {
      .cmp-text {
        p {
          b {
            color: $color-dark-gray;
          }
        }
      }
    }
  }
}

.container-bgcolor-black {
  .cmp-container {
    .text-title-list-style {
      .cmp-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $color-white;
        }
      }
    }
  }
}

.bg-white {
  .cmp-container {
    .article-text-style-1 {
      &.article-text-display-style-1 {
        .cmp-text p b {
          color: $color-charcoal;
        }
      }
    }
  }
}
.tab-list-style {
  &.bg-charcoal {
    .bg-white {
      .cmp-container {
        .article-text-style-1 {
          &.article-text-display-style-1 {
            .cmp-text {
              p,
              a,
              ul li,
              ol li,
              span,
              blockquote {
                color: $color-dark-gray;
              }
            }
          }
        }
      }
    }
  }
}

.bg-white {
  & > .cmp-container {
    .article-text-style-1 {
      &.article-text-display-style-1 {
        .cmp-text {
          p a, ul li, ol li, li a {
            color: $color-dark-gray;
          }
        }
      }
    }
  }
}

.bg-charcoal, .bg-white {
  .cmp-container {
    .article-text-style-1 {
      &.article-text-display-style-1 {
        .cmp-text {
          ol {
            margin-bottom: 0;
            padding-bottom: 50px;
            @include breakpoint(desktop-small) {
              padding-bottom: 70px;
            }
          }
        }
      }
    }
  }
}

.container-inspired-banner-with-image-text-left {
  .cmp-container {
    .inspired-banner-image-text-left {
      .cmp-teaser__action-container {
        @media screen and (max-width: 1600px) {
          margin-bottom: 50px;
        }
      }
    }
  }
}
.find-a-store-tile-container {
  .aem-Grid {
    display: inherit;
    justify-content: space-between;
    @include breakpoint(tab-landscape) {
      display: block;
      width: 100%;
    }
  }
}
.tab-list-style {
  .vertical-tabs {
    .cmp-tabs {
      &__tabpanel {
        @include breakpoint(tab-landscape) {
          border-top: none;
        }
      }
    }
  }
}
.nav-dark-theme {
  .header-mega-menu-container {
    background-color: $color-black;
    &:hover {
      .search-button {
        .search-text {
          color: $color-white;
        }
        .search-icon {
          img {
            filter: invert(100) sepia(100%) saturate(0) hue-rotate(346deg)
              brightness(161%) contrast(125%);
          }
        }
      }
      .cmp-tabs {
        .cmp-tabs__tablist {
          .cmp-tabs__tab {
            @include breakpoint(desktop-extra-lite-sm) {
              color: $color-white !important;
            }
          }
        }
      }
    }
    &.nav-up {
      background-color: $color-black !important;
      .search-button {
        .search-text {
          color: $color-white !important;
        }
        .search-icon {
          img {
            filter: invert(100) sepia(100%) saturate(0) hue-rotate(346deg)
              brightness(161%) contrast(125%) !important;
          }
        }
      }
    }
  }
  .cmp-container {
    .brand-logo {
      img {
        filter: invert(100) sepia(100%) saturate(0) hue-rotate(346deg)
          brightness(161%) contrast(125%);
      }
    }
  }
  .search-button {
    .search-text {
      color: $color-white;
    }
    .search-icon {
      img {
        filter: invert(100) sepia(100%) saturate(0) hue-rotate(346deg)
          brightness(161%) contrast(125%);
      }
    }
  }
  &.searchbar-open {
    .header-mega-menu-container {
      background-color: $color-black;
    }
  }
}
@include breakpoint(desktop-extra-lite-sm) {
  .nav-light-theme {
    .header-mega-menu-container {
      background-color: $color-white;
      &:hover {
        background-color: $color-white;
        .cmp-tabs {
          .cmp-tabs__tablist {
            .cmp-tabs__tab {
              color: $color-charcoal !important;
            }
          }
        }
      }
    }
  }
}
.inspired-banner-with-large-image-text-left,
.inspired-banner-with-large-image-text-right {
  &.aem-GridColumn {
    @media (max-width: 1038px) {
      padding: 81px 0 25px;
    }
  }
}
.container-inspired-selling-4up-content {
  .cmp-container {
    .inspired-selling-4up-content {
      .cmp-teaser__title {
        font-size: 16px;
        @include breakpoint(tab-max) {
          font-size: 14px;
        }
        @include breakpoint(desktop-extra-sm) {
          font-size: 10.5px;
        }
        a {
          font-size: 16px;
          @include breakpoint(tab-max) {
            font-size: 14px;
          }
          @include breakpoint(desktop-extra-sm) {
            font-size: 10.5px;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 16px;
            @include breakpoint(tab-max) {
              font-size: 14px;
            }
            @include breakpoint(desktop-extra-sm) {
              font-size: 10.5px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tab-max) {
  .kohler-partner-number-teaser {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser {
          &__pretitle {
            font-size: 46px;
            line-height: 50px;
            left: 0;
            p {
              font-size: 46px;
              line-height: 50px;
              letter-spacing: -3.4px;
              left: 1pc;
              position: absolute;
            }
          }
          &__title {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

.help-support {
  .help-support-right {
    .chat:first-child {
      .support-links {
        @include breakpoint(mobile-potrait) {
          left: 5px;
        }
      }
      @media screen and (min-width: 1024px) and (max-width: 1025px) {
        ul {
          li {
            &.chat-button {
              a {
                min-width: 0px;
              }
            }
          }
        }
      }

      .chat-button {
        display: block;
      }
    }
  }
}

.nav-light-theme,
.nav-dark-theme {
  &.add-body-class {
    .utilitynavheader {
      .gbh-utility-nav {
        .gbh-utility-navbar {
          ul {
            .gbh-nav__findStore,
            .gbh-nav__region {
              @include breakpoint(desktop-extra-lite-sm) {
                z-index: 99;
              }
            }
          }
        }
      }
    }
    .header-mega-menu-container {
      .gbh-global-tab {
        &.open {
          .gbh-hamber-menu {
            @include breakpoint(desktop-extra-lite-sm) {
              display: none;
            }
          }
        }
      }
    }
    .utility-widgets {
      .auth-widget {
        .auth-nav__menu-mobile-back-menu {
          .icon-Close-Medium {
            @include breakpoint(desktop-extra-lite-sm) {
              display: block;
            }
          }
        }
      }
    }
  }
  &.gbh-of-scroll {
    .gbh-header-logo-menu {
      .cmp-container {
        .gbh-global-tab {
          .cmp-tabs {
            .cmp-tabs__tablist {
              @include breakpoint(desktop-extra-lite-sm) {
                overflow-y: scroll;
              }
            }
          }
        }
      }
    }
    .utilitynavheader {
      .gbh-utility-nav {
        .gbh-utility-navbar {
          ul {
            padding-bottom: 0;
            li {
              &.wishlist-widget {
                &.mobile_block {
                  @include breakpoint(tab-max-large) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.add-body-class-after-login {
    .gbh-header-logo-menu {
      .cmp-container {
        .gbh-global-tab {
          .cmp-tabs {
            .cmp-tabs__tablist {
              @include breakpoint(desktop-extra-lite-sm) {
                padding-bottom: 112px;
              }              
            }
          }
        }
      }
    }
  }

  .icon-Hamburger {
    &:before {
      @include breakpoint(desktop-extra-lite-sm) {
        content: none;
      }
    }
  }
  .gbh-header-logo-menu {
    .cmp-container {
      .gbh-hamber-menu {
        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          right: 3.94%;
          margin-right: 0;
          margin-top: 0;
        }
        @include breakpoint(mobile-potrait) {
          right: 18px;
        }
      }
      .gbh-global-tab {
        @include breakpoint(desktop-extra-lite-sm) {
          position: relative;
        }
        &.open {
          .cmp-tabs {
            @include breakpoint(mobile-landscape) {
              max-height: 100%;
            }
            @include breakpoint(tab) {
              max-height: 100%;
            }
          }
        }
        .cmp-tabs {
          @media screen and (min-width: $tab-min-giant) and (max-width: $tab-min-giant) {
            overflow: hidden;
          }
          @media screen and (min-width: $ipadMaxTab) and (max-width: $ipadMaxTab) and (min-height:1366px) and (max-height:1367px){
            overflow: hidden;
          }
          @media screen and (min-width: $tab-max-sm) and (max-width: $tab-max-sm){
            overflow: hidden;
          }
          .cmp-tabs__tablist {
            @include breakpoint(desktop-extra-lite-sm) {
              padding-bottom: 270px;
              height: 100%;
              width: 100%;
              overflow: hidden;
              margin-bottom: 0;
            }
            @media screen and (min-width: $smallMobile) and (max-width: $largeTabMax) {
              height: auto;
              padding-bottom: 66px;
            }
            @include breakpoint(tab) {
              height: auto;
              padding-bottom: 66px;
              min-height: 100%;
            }
            @media screen and (min-width: $portraitTab) and (max-width: $largeTabMax) {
              min-height: 100%;
            }
          }
        }
      }
      .wishlist-widget {
        @include breakpoint(desktop-extra-lite-sm) {
          display: none;
        }
      }
      .cart-indicator-widget {
        @include breakpoint(desktop-extra-lite-sm) {
          margin-top: 0;
          padding-left: 30px;
        }
        .cart-indicator {
          .gbh-data-layer {
            img {
              @include breakpoint(desktop-extra-lite-sm) {
                height: 30px;
                width: 25px;
                margin-top: 0;
              }
            }
          }
        }
      }
      .aem-Grid {
        .utilitywidgets {
          .utility-widgets {
            .search-widget {
              .search-button {
                .search-icon {
                  @include breakpoint(desktop-extra-lite-sm) {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header-mega-menu-container {
    @include breakpoint(desktop-extra-lite-sm) {
      padding: 15px 0;
    }
    .cmp-container {
      @include breakpoint(desktop-extra-lite-sm) {
        padding: 0 9% 0 3.94%;
      }
      @include breakpoint(mobile-landscape) {
        padding: 0 10% 0 3.94%;
      }
      @include breakpoint(mobile-potrait) {
        padding: 0 70px 0 18px;
      }
    }
  }
  .search-button {
    .search-text {
      @include breakpoint(desktop-extra-lite-sm) {
        display: none;
      }
    }
    .search-icon {
      img {
        @include breakpoint(desktop-extra-lite-sm) {
          width: 18px;
          height: 30px;
        }
      }
    }
  }
  .Shared_utilityNavHeaderWrapper__F3u85 {
    @include breakpoint(desktop-extra-lite-sm) {
      position: absolute;
      width: 100%;
      bottom: 54px;
    }
    @media screen and (max-height: 800px) {
      position: unset;
    }
    .gbh-utility-nav {
      .gbh-utility-navbar {
        ul {
          @include breakpoint(desktop-extra-lite-sm) {
            display: flex;
            flex-direction: column;
            padding-bottom: 12px;
          }
          li {
            @include breakpoint(desktop-extra-lite-sm) {
              display: none;
            }
            &.gbh-nav__signin {
              .utility-widgets {
                @include breakpoint(desktop-extra-lite-sm) {
                  border-left: none;
                }
                .auth-widget {
                  @include breakpoint(desktop-extra-lite-sm) {
                    background: $color-extra-light-gray;
                    padding: 20px 100px 0px;
                    bottom: 190px;
                    position: unset;
                  }
                  @media (max-width: $smallMobile) {
                    padding: 20px 49px 0px;
                  }
                  .auth-nav {
                    &__menu-mobile-back-menu {
                      @include breakpoint(desktop-extra-lite-sm) {
                        padding: 20px 35px 20px 30px;
                        margin-left: 0;
                        display: block;
                        text-align: center;
                        position: fixed;
                        width: 100%;
                        background: $color-extra-light-gray;
                        z-index: 9;
                        margin-bottom: 0px;
                      }
                      .icon-Close-Medium {
                        @include breakpoint(desktop-extra-lite-sm) {
                          position: absolute;
                          top: 16px;
                          right: 32px;
                          padding: 0;
                          background: none;
                        }
                        @include breakpoint(mobile-potrait) {
                          right: 18px;
                        }
                        &::before {
                          @include breakpoint(desktop-extra-lite-sm) {
                            content: none;
                          }
                        }
                      }
                      span {
                        @include breakpoint(desktop-extra-lite-sm) {
                          color: $charcoal;
                          margin: 0;
                          font-family: $helvetica-regular;
                          font-size: 18px;
                          line-height: 26px;
                          letter-spacing: -0.35px;
                        }
                        &:before {
                          @include breakpoint(desktop-extra-lite-sm) {
                            left: 32px;
                            position: absolute;
                            content: url(/icons/left-arrow-bold.svg);
                            display: block;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            pointer-events: none;
                            top: 20px;
                          }
                          @include breakpoint(mobile-potrait) {
                            left: 18px;
                          }
                        }
                      }
                    }
                    &__menu-open {
                      .auth-nav__menu-wrap {
                        @include breakpoint(desktop-extra-lite-sm) {
                          margin: 0;
                          padding-top: 65px;
                        }
                        .auth-nav__menu-items {
                          p {
                            @include breakpoint(desktop-extra-lite-sm) {
                              padding-top: 0;
                            }
                            a {
                              @include breakpoint(desktop-extra-lite-sm) {
                                font-family: $helvetica-regular;
                                font-size: 18px;
                                line-height: 26px;
                                letter-spacing: -0.4px;
                                color: $charcoal;
                                margin-bottom: 15px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .auth-nav__menu-wrap {
                      .auth-nav__menu-items {
                        a {
                          @include breakpoint(desktop-extra-lite-sm) {
                            font-family: $helvetica-regular;
                            color: $charcoal;
                            width: 100%;
                            min-width: unset;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: -0.4px;
                          }
                        }
                        .sign-out {
                          a {
                            @include breakpoint(desktop-extra-lite-sm) {
                              font-size: 16px;
                              line-height: 24px;
                              letter-spacing: -0.4px;
                              margin-top: 40px;
                              margin-bottom: 0;
                            }
                          }
                        }
                        span {
                          @include breakpoint(desktop-extra-lite-sm) {
                            padding-bottom: 0px;
                            line-height: initial;
                            display: inline-block;
                            position: relative;
                            #kf-react-sign-in {
                              @include breakpoint(desktop-extra-lite-sm) {
                                padding-right: 14px;
                              }
                              &:before {
                                @include breakpoint(desktop-extra-lite-sm) {
                                  position: absolute;
                                  content: "/";
                                  right: 2px;
                                  top: 2px;
                                }
                              }
                            }
                            #kf-react-sign-up {
                              @include breakpoint(desktop-extra-lite-sm) {
                                padding-left: 2px;
                                padding-right: 0;
                              }
                              img {
                                @include breakpoint(desktop-extra-lite-sm) {
                                  opacity: 0;
                                  visibility: hidden;
                                  margin-left: -35px;
                                }
                              }
                            }
                          }
                        }
                        .order-status-signin {
                          @include breakpoint(desktop-extra-lite-sm) {
                            display: flex;
                            align-items: center;
                          }
                          p {
                            a {
                              @include breakpoint(desktop-extra-lite-sm) {
                                margin-bottom: 0;
                                padding-top: 20px;
                              }
                            }
                          }
                          img {
                            @include breakpoint(desktop-extra-lite-sm) {
                              margin-right: 5px;
                            }
                          }
                        }
                      }
                    }
                    &__user {
                      @include breakpoint(desktop-extra-lite-sm) {
                        font-family: $helvetica-regular;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.4px;
                        color: $charcoal;
                      }
                    }
                    &__sign-in-right-arrow {
                      @include breakpoint(desktop-extra-lite-sm) {
                        display: none;
                      }
                    }
                    &__menu {
                      @include breakpoint(desktop-extra-lite-sm) {
                        top: 0;
                        padding: 0;
                        height: 100vh;
                      }
                      @include breakpoint(desktop-extra-lite-sm) {
                        background-color: $color-extra-light-gray;
                      }
                      .auth-nav__menu-items {
                        @include breakpoint(desktop-extra-lite-sm) {
                          padding: 0 100px;
                          margin-left: 0;
                        }
                        @include breakpoint(mobile-potrait) {
                          padding: 0 49px;
                        }
                      }
                    }
                    &__sign-in-avatar {
                      @include breakpoint(desktop-extra-lite-sm) {
                        margin-right: 5px;
                      }
                    }
                    &__sign-in-mobile {
                      @include breakpoint(desktop-extra-lite-sm) {
                        align-items: center;
                      }
                    }
                  }
                  .auth-nav--active {
                    .auth-nav__menu-expand {
                      .auth-nav__menu-wrap {
                        .auth-nav__menu-items {
                          .order-status-signin {
                            @include breakpoint(desktop-extra-lite-sm) {
                              display: flex;
                              align-items: center;
                            }
                            p {
                              a {
                                @include breakpoint(desktop-extra-lite-sm) {
                                  margin-bottom: 0;
                                  padding-top: 15px;
                                  letter-spacing: -0.5px;
                                }
                                &:first-child {
                                  @include breakpoint(desktop-extra-lite-sm) {
                                    padding-top: 20px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .wishlist-widget {
                  @include breakpoint(desktop-extra-lite-sm) {
                    bottom: 64px;
                    position: fixed;
                    left: 0;
                    top: auto;
                    width: 100%;
                    padding-left: 0;
                    background: $color-extra-light-gray;
                    display: block !important;
                    padding-right: 0;
                    border-bottom: 1px solid $color-dark-gray;
                  }
                  a {
                    @include breakpoint(desktop-extra-lite-sm) {
                      display: block;
                      padding: 20px 100px 30px;
                      width: 100%;
                      font-family: $helvetica-regular;
                      font-size: 16px;
                      line-height: 24px;
                      letter-spacing: -0.4px;
                      color: $charcoal;
                      margin: o;
                      @include breakpoint(mobile-potrait) {
                        padding: 20px 49px 30px;
                      }
                    }
                  }
                }
              }
            }
            &.gbh-nav__region {
              @include breakpoint(desktop-extra-lite-sm) {
                background: $color-extra-light-gray;
                padding: 20px 100px;
                z-index: 999;
                border-top: 1px solid $color-dark-gray;
              }
              @media (max-width: $smallMobile) {
                padding: 20px 49px;
              }
              #gbh_utheader {
                .gbh-tab__panels {
                  .gbh-close {
                    @include breakpoint(desktop-extra-lite-sm) {
                      display: none;
                    }
                  }
                }
              }
              a {
                @include breakpoint(desktop-extra-lite-sm) {
                  font-family: $helvetica-regular;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.4px;
                  color: $charcoal;
                  width: 100%;
                }
                &.gbh-nav__region--link {
                  &:after {
                    @include breakpoint(desktop-extra-lite-sm) {
                      display: none;
                    }
                  }
                }
              }
            }
            &.gbh-nav__findStore {
              @include breakpoint(desktop-extra-lite-sm) {
                top: auto;
                bottom: 122px;
                z-index: unset;
                background: $color-extra-light-gray;
                position: unset;
                margin: -1px 0;
              }
              > a {
                @include breakpoint(desktop-extra-lite-sm) {
                  color: $charcoal;
                  margin: 0;
                  font-family: $helvetica-regular;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.4px;
                  position: unset;
                  display: block;
                  text-align: initial;
                  padding-left: 0px;
                  padding: 20px 100px;
                }
                @media (max-width: $smallMobile) {
                  padding: 20px 49px;
                }
                &.gbh-nav__findStore--link {
                  &:before {
                    @include breakpoint(desktop-extra-lite-sm) {
                      content: none;
                    }
                  }
                }
              }
              .v1_findAStoreUtilNavWrapper__zMdNe {
                @include breakpoint(desktop-extra-lite-sm) {
                  top: 0;
                  background-color: $color-charcoal;
                  bottom: auto;
                  position: fixed;
                  z-index: 1000;
                  width: 100%;
                }
                .gbh-find-a-store.active {
                  .findstore-util-nav {
                    .findstore-util-nav__content {
                      @media screen and (min-width: 768px) and (max-width: 991px) {
                        padding-bottom: 170px;
                      }
                      @media screen and (max-width: 767px) {
                        padding-bottom: 70px;
                      }
                    }
                  }
                }
              }
            }
            a {
              img {
                @include breakpoint(desktop-extra-lite-sm) {
                  width: auto;
                  height: auto;
                  margin-right: 5px;
                  margin-top: 0;
                }
              }
            }
            &:first-child {
              @include breakpoint(desktop-extra-lite-lg) {
                padding: 0 !important;
              }
              @include breakpoint(desktop-extra-lite-sm) {
                border-bottom: none;
              }
            }
            &.wishlist-widget {
              &.mobile_block {
                @include breakpoint(desktop-extra-lite-sm) {
                  padding: 0;
                  bottom: 64px;
                  position: unset;
                  left: 0;
                  top: auto;
                  width: 100%;
                  padding-left: 0;
                  background: $color-extra-light-gray;
                  padding-right: 0;
                }
                a {
                  @include breakpoint(desktop-extra-lite-sm) {
                    padding: 0 100px 20px;
                    display: block;
                    width: 100%;
                    font-family: $helvetica-regular;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.4px;
                    color: $color-charcoal;
                  }
                  @media (max-width: $smallMobile) {
                    padding: 0 49px 20px;
                  }
                }
              }
            }
          }
          .mobile_none {
            @include breakpoint(desktop-extra-lite-sm) {
              display: none !important;
            }
          }
        }
      }
    }
  }
  .brand-switcher + .Shared_utilityNavHeaderWrapper__F3u85 {
    @include breakpoint(desktop-extra-lite-sm) {
      display: none;
    }
  }
  .utilitynavheader {
    @include breakpoint(desktop-extra-lite-sm) {
      background-color: $color-white;
      padding: 0;
    }
    .gbh-utility-nav {
      .gbh-utility-navbar {
        ul {
          @include breakpoint(desktop-extra-lite-sm) {
            margin: 0;
          }
        }
      }
    }
  }
  .utility-widgets {
    .cart-indicator-widget {
      @include breakpoint(desktop-extra-lite-sm) {
        margin-top: 0;
        padding-left: 30px;
      }
    }
  }
}
.nav-dark-theme {
  .icon-Hamburger {
    &:before {
      @include breakpoint(desktop-extra-lite-sm) {
        content: url(/icons/white-hamburger-nav.svg);
      }
    }
    img {
      @include breakpoint(desktop-extra-lite-sm) {
        display: none;
      }
    }
  }
  .cart-indicator-widget {
    .cart-indicator {
      .gbh-data-layer {
        img {
          @include breakpoint(desktop-extra-lite-sm) {
            -webkit-filter: invert(100%) sepia(100%) saturate(0)
              hue-rotate(346deg) brightness(104%) contrast(102%);
            filter: invert(100%) sepia(100%) saturate(0) hue-rotate(346deg)
              brightness(104%) contrast(102%);
          }
        }
      }
    }
  }
  .Shared_utilityNavHeaderWrapper__F3u85 {
    .gbh-utility-nav {
      .gbh-utility-navbar {
        ul {
          li {
            &.gbh-nav__signin {
              .utility-widgets {
                .auth-widget {
                  @include breakpoint(desktop-extra-lite-sm) {
                    background: $color-charcoal;
                  }
                  .auth-nav {
                    &__menu-mobile-back-menu {
                      @include breakpoint(desktop-extra-lite-sm) {
                        background: $color-charcoal;
                      }
                      img {
                        @include breakpoint(desktop-extra-lite-sm) {
                          display: inline-block;
                          -webkit-filter: invert(100%) sepia(100%) saturate(0)
                            hue-rotate(346deg) brightness(104%) contrast(102%);
                          filter: invert(100%) sepia(100%) saturate(0)
                            hue-rotate(346deg) brightness(104%) contrast(102%);
                        }
                      }
                      span {
                        @include breakpoint(desktop-extra-lite-sm) {
                          color: $color-white;
                        }
                        &:before {
                          @include breakpoint(desktop-extra-lite-sm) {
                            content: url(/icons/white-left-bold.svg);
                          }
                        }
                      }
                    }
                    .auth-nav__menu-wrap {
                      .auth-nav__menu-items {
                        .order-status-signin {
                          p {
                            a {
                              @include breakpoint(desktop-extra-lite-sm) {
                                color: $white;
                              }
                            }
                          }
                        }
                        a {
                          @include breakpoint(desktop-extra-lite-sm) {
                            color: $white;
                          }
                        }
                      }
                    }
                    &__user {
                      @include breakpoint(desktop-extra-lite-sm) {
                        color: $white;
                      }
                    }
                    &__menu {
                      @include breakpoint(desktop-extra-lite-sm) {
                        background-color: $color-charcoal;
                      }
                    }
                    &__sign-in-avatar {
                      @include breakpoint(desktop-extra-lite-sm) {
                        -webkit-filter: invert(100%) sepia(100%) saturate(0)
                          hue-rotate(346deg) brightness(104%) contrast(102%);
                        filter: invert(100%) sepia(100%) saturate(0)
                          hue-rotate(346deg) brightness(104%) contrast(102%);
                      }
                    }
                  }
                }
                .wishlist-widget {
                  @include breakpoint(desktop-extra-lite-sm) {
                    background: $color-charcoal;
                    border-bottom: 1px solid $color-dark-gray;
                  }
                  a {
                    @include breakpoint(desktop-extra-lite-sm) {
                      color: $color-white;
                    }
                  }
                }
              }
            }
            &.gbh-nav__region {
              @include breakpoint(desktop-extra-lite-sm) {
                background: $charcoal;
              }
              a {
                @include breakpoint(desktop-extra-lite-sm) {
                  color: $white;
                }
              }
            }
            &.gbh-nav__findStore {
              @include breakpoint(desktop-extra-lite-sm) {
                background: $color-charcoal;
              }
              > a {
                @include breakpoint(desktop-extra-lite-sm) {
                  color: $color-white;
                }
                img {
                  @include breakpoint(desktop-extra-lite-sm) {
                    -webkit-filter: invert(100%) sepia(100%) saturate(0)
                      hue-rotate(346deg) brightness(104%) contrast(102%);
                    filter: invert(100%) sepia(100%) saturate(0)
                      hue-rotate(346deg) brightness(104%) contrast(102%);
                  }
                }
              }
            }
            a {
              img {
                @include breakpoint(desktop-extra-lite-sm) {
                  -webkit-filter: invert(100%) sepia(100%) saturate(0)
                    hue-rotate(346deg) brightness(104%) contrast(102%);
                  filter: invert(100%) sepia(100%) saturate(0)
                    hue-rotate(346deg) brightness(104%) contrast(102%);
                }
              }
            }
            .order-status-signin {
              img {
                @include breakpoint(desktop-extra-lite-sm) {
                  -webkit-filter: invert(100%) sepia(100%) saturate(0)
                    hue-rotate(346deg) brightness(104%) contrast(102%);
                  filter: invert(100%) sepia(100%) saturate(0)
                    hue-rotate(346deg) brightness(104%) contrast(102%);
                }
              }
            }
          }
        }
      }
    }
  }
}

/*------ Nav Transparent Theme------*/
.nav-light-theme {
  &.nav-transparent-light-theme {
    .header-mega-menu-container {
      background-color: transparent;
      &:hover {
        background-color: $color-white;
        .cmp-tabs {
          .cmp-tabs__tablist {
            .cmp-tabs__tab {
              @include breakpoint(desktop-extra-lite-sm) {
                color: $color-charcoal !important;
              }
            }
          }
        }
        .search-button {
          .search-text {
            color: $color-charcoal;
          }
          .search-icon {
            img {
              filter: unset;
            }
          }
        }
        @include breakpoint(desktop-extra-lite-sm) {
          .cart-indicator-widget {
            .cart-indicator {
              .gbh-data-layer {
                img {
                  filter: unset;
                }
              }
            }
          }
          .gbh-hamber-menu {
            img {
              filter: unset;
            }
          }
        }
        .cmp-container {
          .brand-logo {
            img {
              filter: unset;
            }
          }
        }
        &.searchbar-open {
          .header-mega-menu-container {
            background-color: $color-white;
          }
        }
        .gbh-global-tab {
          .cmp-tabs {
            &__tablist {
              &__tab {
                color: $color-charcoal;
              }
            }
          }
        }
      }
      .gbh-global-tab {
        .cmp-tabs {
          .cmp-tabs__tablist {
            .cmp-tabs__tab {
              color: $color-white;
            }
          }
        }
        &.open {
          .gbh-hamber-menu {
            img {
              @include breakpoint(desktop-extra-lite-sm) {
                filter: unset;
              }
            }
          }
        }
      }
      .search-button {
        .search-text {
          color: $color-white;
        }
        .search-icon {
          img {
            @include nav-filter;
          }
        }
      }
      @include breakpoint(desktop-extra-lite-sm) {
        .cart-indicator-widget {
          .cart-indicator {
            .gbh-data-layer {
              img {
                @include nav-filter;
              }
            }
          }
        }
        .gbh-hamber-menu {
          img {
            @include nav-filter;
          }
        }
      }
      .cmp-container {
        .brand-logo {
          img {
            @include nav-filter;
          }
        }
      }
      &.searchbar-open {
        .header-mega-menu-container {
          background-color: transparent;
        }
      }
      &.nav-up {
        .cmp-container {
          .brand-logo {
            img {
              filter: unset;
            }
          }
        }
        @include breakpoint(desktop-extra-lite-sm) {
          .cart-indicator-widget {
            .cart-indicator {
              .gbh-data-layer {
                img {
                  filter: unset;
                }
              }
            }
          }
          .gbh-hamber-menu {
            img {
              filter: unset;
            }
          }
        }
      }
    }
  }
}
.nav-dark-theme {
  &.nav-transparent-dark-theme {
    .header-mega-menu-container {
      background-color: transparent;
      &:hover {
        background-color: $color-black;
        .cmp-tabs {
          .cmp-tabs__tablist {
            .cmp-tabs__tab {
              @include breakpoint(desktop-extra-lite-sm) {
                color: $color-white !important;
              }
            }
          }
        }
      }
    }
  }
}

.tab-list-style {
  &.bg-slate,
  &.bg-charcoal,
  &.bg-off-white {
    .cmp-carousel {
      .os-theme-dark {
        .os-scrollbar-track {
          background-color: $color-light-gray;
          .os-scrollbar-handle {
            background-color: $color-dark-gray;
          }
        }
      }
    }
  }
}
@include breakpoint(mobile-landscape) {
  .video,
  .hero-banner-des-medium-center {
    height: auto;
    margin-top: -85px;
  }
  .hero-banner-container {
    .video.hero-banner-full-bleed-image {
      margin-top: -65px;
    }
  }
  breadcrumb + .teaser,
  .breadcrumb + .teaser.video {
    margin-top: -148px;
  }
  .store-hero-banner {
    margin-top: -148px;
  }
}
@include breakpoint(tab-min) {
  .hero-banner-container {
    .video.hero-banner-full-bleed-image {
      margin-top: -63px;
    }
  }
  breadcrumb + .teaser,
  .breadcrumb + .teaser.video {
    margin-top: -148px;
  }
}
@include breakpoint(mobile-potrait) {
  .hero-banner-container {
    .video.hero-banner-full-bleed-image {
      margin-top: -65px;
    }
  }
  breadcrumb + .teaser,
  .breadcrumb + .teaser.video {
    margin-top: -148px;
  }
}

.header-mega-menu-container {
  .cmp-tabs {
    &.light-theme,
    &.dark-theme {
      .cmp-tabs {
        &__tablist {
          @media screen and (min-width: 1201px) {
            position: unset !important;
          }
        }
      }
      .cmp-container {
        @include breakpoint(desktop-large) {
          padding: 0 2.6%;
        }
      }
    }
  }
}

.preview-cart-item__footer {
  .preview-cart-item__qty {
    .quantity-selector {
      border-radius: none;
      button {
        border-radius: 0;
        border-style: none;
        background: unset;
      }
    }
  }
}

.tab-list-style {
  &.bg-off-white,
  &.bg-white,
  &.bg-charcoal {
    .cmp-container {
      .container-inspired-banner-with-image-text-right,
      .container-inspired-banner-with-image-text-left,
      .inspired-banner-with-large-image-text-right,
      .inspired-banner-with-large-image-text-left,
      .inspired-banner-with-two-images-text-right,
      .inspired-banner-with-two-images-text-left {
        &.bg-off-white {
          .cmp-container {
            .cmp-teaser {
              &__description {
                p,
                ul li,
                ol,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                sub,
                blockquote,
                span,
                a,
                ol li {
                  color: $color-slate;
                }
              }
            }
            .shop-the-room-detail-container {
              .text-images-container {
                .shop-the-room-detail-text-container {
                  .shop-the-room-detail-textbox {
                    .card-title {
                      h1,
                      h2,
                      h3,
                      h4,
                      h5,
                      h6 {
                        color: $color-slate;
                      }
                    }
                    .card-description {
                      p {
                        color: $color-slate;

                        a {
                          color: $color-slate;
                        }
                      }

                      ul li,
                      ol,
                      h1,
                      h2,
                      h3,
                      h4,
                      h5,
                      h6,
                      sub,
                      blockquote,
                      span,
                      a {
                        color: $color-slate;
                      }

                      ol li {
                        color: $color-slate;
                      }
                    }
                  }
                }
              }
            }
            .view-more-button-text {
              p {
                color: $color-dark-gray;
              }
            }
          }
        }
        &.bg-charcoal {
          .cmp-container {
            .shop-the-room-detail-container {
              .text-images-container {
                .shop-the-room-detail-text-container {
                  .shop-the-room-detail-textbox {
                    .card-description {
                      p,
                      ul li,
                      ol,
                      h1,
                      h2,
                      h3,
                      h4,
                      h5,
                      h6,
                      sub,
                      blockquote,
                      span,
                      ol li,
                      a,
                      b {
                        color: $color-light-gray;

                        a {
                          color: $color-light-gray;
                        }
                      }
                    }
                  }
                }
              }
              .view-more-products-container {
                .card-details__container,
                .card-details-descriprion__container {
                  p {
                    color: $white;
                  }
                }
              }
            }
            .view-more-button-text {
              p {
                color: $color-light-gray;
              }
            }
          }
        }
      }
    }
  }
}
.view-more-button-text {
  p {
    color: $color-dark-gray;
    letter-spacing: -0.55px;
  }
}

.tab-list-style {
  .cmp-container {
    .container-inspired-banner-with-image-text-right,
    .container-inspired-banner-with-image-text-left,
    .inspired-banner-with-large-image-text-right,
    .inspired-banner-with-large-image-text-left,
    .inspired-banner-with-two-images-text-right,
    .inspired-banner-with-two-images-text-left {
      .cmp-container {
        .shop-the-room-detail-container {
          .text-images-container {
            .shop-the-room-detail-text-container {
              .shop-the-room-detail-textbox {
                @include breakpoint(desktop-lg) {
                  .card-title {
                    padding-top: 20px;
                  }
                }
              }
            }
          }
        }
        .teaser {
          .cmp-teaser__description {
            font-size: 20px;
            @include breakpoint(desktop-extra-lite-sm) {
              font-size: 16px;
            }
            p {
              font-size: 20px;
              @include breakpoint(desktop-extra-lite-sm) {
                font-size: 16px;
              }
              a {
                font-size: 20px;
                @include breakpoint(desktop-extra-lite-sm) {
                  font-size: 16px;
                }
              }

              ul li,
              ol,
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              sub,
              blockquote,
              span,
              a {
                font-size: 20px;
                @include breakpoint(desktop-extra-lite-sm) {
                  font-size: 16px;
                }
              }
              ol li {
                font-size: 20px;
                @include breakpoint(desktop-extra-lite-sm) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tab-list-style {
  &.bg-charcoal {
    .faq-accordion {
      .cmp-accordion__title {
        color: $color-slate;
      }
    }
    .bg-charcoal {
      &.copy-rich-basic-text {
        .cmp-container {
          .faq-accordion {
            .cmp-accordion__title {
              color: $color-white;
            }
          }
        }
      }
    }
    .bg-slate {
      &.room-inspiration-carousel {
        .cmp-carousel {
          &.cmp-carousel-slick {
            .carousel-header-info {
              .cmp-carousel__description {
                p,
                a,
                b,
                span {
                  color: $color-extra-light-gray;
                }
              }
            }
          }
        }
      }
    }
  }
  &.bg-charcoal, &.bg-slate {
    .bg-off-white {
      &.teaser-complimentary-guide { 
        &.teaser {
          .cmp-teaser {
            &__pretitle, &__title, &__description {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p,
              a,
              b,
              p a,
              p b,
              ul li,
              ol li,
              span,
              blockquote {
                color: $color-slate;
              }
            }
          }
        }         
      }
    }
  }  
  &.bg-slate,
  &.bg-off-white,
  &.bg-white,
  &.bg-charcoal,
  &.bg-extra-light-grey {
    .bg-off-white,
    .bg-white {
      &.copy-rich-basic-text {
        .cmp-container {
          .teaser {
            .cmp-teaser {
              &__description {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                a,
                b,
                p a,
                p b,
                ul li,
                ol li,
                span,
                blockquote {
                  color: $color-dark-gray;
                }
                .more {
                  color: $color-slate;
                }
              }
            }
          }
        }
      }
    }
    .container-complimentary-guide {
      background-color: $color-white;
      .aem-genericForm {
        .planning-form-text {
          p {
            color: $color-slate;
          }
        }
        .aem-form-mandatory-text {
          p {
            color:$color-dark-gray;
          }
        }
      }
    }
    .bg-charcoal {
      &.teaser-complimentary-guide { 
        &.teaser {
          .cmp-teaser {
            &__pretitle, &__title {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p,
              a,
              b,
              p a,
              p b,
              ul li,
              ol li,
              span,
              blockquote {
                color: $color-white;
              }
            }
            &__description {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p,
              a,
              b,
              p a,
              p b,
              ul li,
              ol li,
              span,
              blockquote {
                color: $color-light-gray;
              }
            }
          }
        }         
      }
    }
  }
}
.container-complimentary-guide {
  .bg-off-white {
    @include breakpoint(desktop-small) {
      position: absolute;
    }
  }
}
.organize-teaser-accordion {
  .aem-Grid {
    overflow: auto;
    @include breakpoint(tab-landscape) {
      overflow: unset;
    }
    &:before {
      clear: both;
    }
    .faq-header,
    .faq-accordion {
      float: left;
      @include breakpoint(tab-landscape) {
        float: unset;
      }
      @include breakpoint(desktop-extra-lite-lg) {
        width: 39rem;
      }
      @include breakpoint(tab) {
        width: 100%;
      }
    }
    .faq-accordion {
      float: right;
    }
  }
}
.full-bleed-image-hover {
  .cmp-teaser__content {
    @include breakpoint(desktop-small) {
      width: 280px !important;
    }
  }
}

.tab-list-style {
  &.bg-off-white {
    .bg-charcoal {
      .full-bleed-video-bottom {
        .cmp-teaser__content {
          .cmp-teaser__title {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
              color: $color-white;
            }
          }
        }
      }
    }
  }
  &.bg-white {
    .bg-off-white {
      .full-bleed-video-bottom {
        .cmp-teaser__content {
          .cmp-teaser__description {
            p,
            ul li,
            ol,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            sub,
            blockquote,
            span,
            a {
              color: $color-slate;
              ul li,
              ol,
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              sub,
              blockquote,
              span,
              a {
                color: $color-slate;
              }
            }
          }
        }
      }
    }
  }
}

.video.hero-banner-full-bleed-image {
  @include breakpoint(tab-max) {
    margin-top: -60px;
  }
}
.organize-teaser-accordion {
  .accordion {
    .cmp-accordion__header {
      .cmp-accordion__button {
        border-radius: 0;
        border-style: none;
        background: unset;
      }
    }
  }
}
.inspired-banner-with-video-text-right,
.inspired-banner-with-video-text-left {
  .cmp-container {
    .inspired-banner-video-text-right,
    .inspired-banner-video-text-left {
      .cmp-teaser {
        &__description {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          a,
          ul li,
          ol li,
          span,
          blockquote,
          sub,
          sup,
          b {
            @media screen and (max-width: 1038px) {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
            }
          }
        }
      }
    }
  }
}
.product-list-slider {
  .carousel-btm-scroll-title-small {
    .cmp-carousel {
      .cmp-carousel__whole {
        .cmp-carousel__content {
          padding-left: 0px;
        }
      }
    }
  }
}
.carousel {
  &.carousel-bottom-scroll {
    &.justify-content-center {
      &.three-items {
        background-color: $white;
      }
    }
  }
}
.carousel-promo-banner {
  .cmp-carousel-slick {
    .cmp-carousel-slick__content {
      .slick-list {
        .slick-track {
          .cmp-carousel-slick__item {
            .promo-banner {
              @include breakpoint(tab) {
                width: 85.8%;
                padding-left: 0px;
              }
              .cmp-promo__description {
                .cmp-teaser__action-link {
                  width: auto;
                  box-shadow: none;
                  @include breakpoint(tab) {
                    padding: 0 0.5rem;
                  }
                }
                .promo-banner-fragment {
                  @include breakpoint(tab) {
                    padding: 0 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.container-img-overflow-top {
  .cmp-container {
    .design-services-copy {
      &.image-top-overflow {
        &.featured-services-char-limit-title {
          @include breakpoint(mobile-potrait) {
            padding-top: 185px;
          }
        }
      }
    }
  }
}
.container-img-overflow {
  .cmp-container {
    .image-bottom-overflow {
      &.design-services-copy {
        &.featured-services-char-limit-title {
          @include breakpoint(mobile-potrait) {
            padding-top: 185px;
          }
        }
      }
    }
  }
}
.featured-content-carousel {
  .cmp-carousel-slick {
    .cmp-carousel-slick__content { 
      .slick-prev {
        left: calc(50% - 689.5px);
        @include breakpoint(desktop-lg) {
          left: 110px;
        }
        @media only screen and (max-width: $ipadTab) {
          right: 80px;
          left: auto;
        }
      }
      .slick-next {
        right: calc(50% - 689.5px);
        @include breakpoint(desktop-lg) {
          right:110px;
        }
        @media only screen and (max-width: $ipadTab) {
          right: 32px;
        }
        @include breakpoint(mobile-potrait) {
          right: 18px;
        }
      }
      .slick-prev,.slick-next{
        @media only screen and (min-width: $ipadMaxTab) and (max-width: $ipadMaxTab) {
          top:40% !important;
        }
      }
    }
  }
}
