.shipping-address {
  &__top-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  &__shipping-title {
    font-size: 20px !important;
    line-height: 22px !important;
    letter-spacing: 1.82px !important;
    color: $slate !important;
    font-family: $font-bold !important;
    text-transform: uppercase !important;
  }
  &__checkout-fast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-cta {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.52px;
      color: $charcoal;
      font-family: $font-regular;
      @include breakpoint(mobile) {
        margin-top: 10px !important;
        letter-spacing: -0.4px;
      }
      .checkout-sign-in {
        font-family: $font-medium;
        color: $charcoal;
        cursor: pointer;
        text-decoration: underline;
        margin: 0 0 0 10px;
      }
    }
    span {
      @include breakpoint(mobile) {
        line-height: 24px !important;
        letter-spacing: -0.4px !important;
      }
    }
  }
  &__message {
    margin-bottom: 50px;
    &.--sales {
      margin-bottom: 25px;
      .shipping-address__signin-mssg {
        margin-bottom: 10px;
      }
    }
  }
  &__signin-mssg {
    margin-bottom: 20px;
    font-family: $font-medium;
    font-size: 20px;
    letter-spacing: -0.4px;
    line-height: 28px;
    color: $color-charcoal;
  }
  &__sales-email {
    margin-bottom: 50px;
    max-width: 380px;
  }
  &__select-address {
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
    color: $color-dark-gray;
  }
  &__address-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 20px;
    .address-tile__cta {
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 22px;
    }
  }
  &__submit {
    button {
      min-width: 245px;
      margin-top: 30px;
      height: 50px !important;
      &.save {
        min-width: 120px;
      }
    }
  }
  &__note {
    margin-top: 50px;
    &-title {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
    &-desc {
      margin-top: 5px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }
    .textarea__wrapper {
      margin-top: 25px;
    }
  }
  &__signup-success {
    border-radius: 5px;
    background-color: $color-off-white;
    padding: 30px;
    margin-bottom: 50px;

    h1 {
      color: $color-charcoal;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.4px;
      line-height: 28px;
      margin-bottom: 5px;
      font-family: $font-medium;
    }

    p {
      color: $color-slate;
      font-size: 20px;
      letter-spacing: -0.65px;
      line-height: 28px;
    }
  }
  &__process-error,
  &__process-error p {
    text-align: right;
    margin-top: 20px;
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
  }
  .instruction {
    max-width: 540px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
    @include breakpoint(tab-max) {
      max-width: 516px;
    }
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $color-dark-gray;
    margin: 0 0 30px 0;
  }
}

.shipping-form {
  &__required-fields {
    display: inline-block;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    margin-bottom: 20px;
    @media only screen and (min-width: 812px) and (max-width: 1023px) {
      margin-bottom: 40px;
    }
  }
  &__set-default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .checkbox {
      margin-bottom: 0;
      input:checked ~ span {
        color: $color-charcoal;
      }
    }
    .shipping-form__label-input {
      margin-top: 0;
      margin-bottom: 0;
    }
    &-check {
      display: inline-flex;
      height: 60px;
    }
    &.--hide-label {
      margin-top: 10px;
      .shipping-form__set-default-check {
        height: auto;
      }
    }
  }
  &__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: $color-charcoal;
      font-family: $font-medium;
      font-size: 16px;
      letter-spacing: -0.35px;
      line-height: 24px;
    }
    .switch {
      min-width: 60px;
      margin-bottom: 0;
      @media screen and (min-width: 778px) and (max-width: 991px) {
        min-width: unset;
      }
    }
  }
  &__label-input {
    margin-top: 20px;
  }
  &__submit-button {
    button {
      min-width: 245px;
      margin-top: 10px;
      height: 50px !important;
      &.save {
        min-width: 120px;
      }
      @media screen and (max-width: 1023px) {
        margin-top: 8px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        min-width: 100%;
      }
    }
  }

  &__auth {
    padding: 30px;
    border-radius: 5px;
    background-color: $color-off-white;
    margin-bottom: 30px;

    &-title {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      margin-bottom: 5px;
    }
    &-desc {
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-slate;
      margin-bottom: 30px;
    }
    &-cta {
      margin-top: 10px;
      color: $color-slate;
      display: flex;
      .button .ghost {
        color: $color-slate;
      }
    }
    &-cta-create-account {
      margin-right: 20px;
    }
  }
  &__pl10 {
    padding-left: 10px !important;
  }
  &__pr10 {
    padding-right: 10px !important;
    @media screen and (max-width: 767px) {
      &.shipping-form__pl10 {
        .select-dropdown {
          margin: 20px 0 !important;
        }
      }
    }
  }
  .hidden-state-input {
    z-index: -1;
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }
}

.check-out__shipping-modal {
  .modal-popup {
    .modal-popup-content {
      .modal-popup-body {
        width: 780px;
        padding: 35px 60px 50px;
      }
      @media screen and (max-width: 1023px) {
        width: 100%;
        max-height: 100% !important;
        padding: 20px 32px 50px;
        .modal-popup-body {
          width: 100%;
          min-width: 339px;
          padding: 20px 0 0 0 !important;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 35px 18px 50px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .shipping-address {
    &__message {
      margin-bottom: 60px;
    }
    &__address-list {
      grid-template-columns: 1fr;
      row-gap: 20px;
      column-gap: 0;
    }
    &__submit {
      float: none;
      width: 100%;
    }
  }
  .shipping-form {
    &__submit-button {
      width: 100%;
    }
    &__auth-cta {
      display: block;
      .button {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .shipping-form {
    &__pl10 {
      padding-left: 15px !important;
    }
    &__pr10 {
      padding-right: 15px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .shipping-form {
    &__required-fields {
      margin-bottom: 40px;
    }
  }
  .shipping-address {
    &__checkout-fast {
      display: block;
      span {
        line-height: 1.31;
        letter-spacing: -1.55px;
      }
      &-cta {
        margin-top: 20px;
        .button {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .shipping-form {
    &__auth {
      padding-right: 30px 20px;
    }
  }
}
.shipping-title-complete {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.35px;
  font-family: $font-medium;
  color: $slate;
  margin: 0 0 5px 0;
}
.check-out {
  .step-nav__title,
  .step-nav__step,
  .step-nav .step-nav__number,
  .step-nav__number--mobile {
    display: none !important;
  }
  .step-nav__step--active,
  .step-nav__step--done {
    display: block !important;
  }
  .step-nav__step--done .step-nav__title {
    display: flex !important;
  }
  .step-nav__title h2,
  .delivery-option .option-title {
    font-size: 20px !important;
    line-height: 23px !important;
    letter-spacing: 1.82px !important;
    color: $slate !important;
    font-family: $font-bold !important;
    text-transform: uppercase !important;
  }
}
.delivery-option .option-title {
  font-size: 20px !important;
  line-height: 22px !important;
  letter-spacing: 1.82px !important;
  color: $slate !important;
  font-family: $font-bold !important;
  text-transform: uppercase !important;
}
.check-out__content {
  @media screen and (max-width: 1023px) {
    .step-nav__edit-btn {
      display: block !important;
    }
    .step-nav__component {
      height: unset !important;
    }
    .step-nav__plus,
    .step-nav__edit-btn--mobile {
      display: none !important;
    }
  }
  .step-nav__step--done {
    .step-nav__edit-btn {
      button {
        border: unset;
        padding: 0 0 4px 0;
        border-bottom: 1px solid $slate;
        color: $slate;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-decoration: unset;
        height: unset;
        min-width: unset;
        &:hover {
          background-color: unset !important;
          transform: unset;
        }
      }
    }
  }
}

.check-out {
  .shipping-alert {
    &.verify-address {
      .shipping-alert__modal {
        @include breakpoint(desktop-small) {
          width: 500px !important;
          padding: 25px 50px 50px !important;
        }
        @include breakpoint(mobile-landscape) {
          padding: 15px 32px 50px !important;
        }
        @include breakpoint(mobile-potrait) {
          padding: 15px 18px 50px !important;
        }
        .shipping-alert__title {
          @include text-styles($font-bold, 1.25rem, 1.5rem, 0.1136rem);
          text-transform: uppercase;
          color: $slate;
          padding-top: 30px;
        }
        .shipping-alert__description {
          color: $color-slate;
          @include breakpoint(tab-landscape) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
          }
          @include breakpoint(mobile-potrait) {
            margin-top: 10px;
          }
        }
        .verify-address__ctas {
          margin-top: 30px !important;
          width: 100% !important;
          flex-direction: column;
          .kf-react-button {
            button {
              width: 100%;
            }
            &:nth-child(2) {
              button {
                border: 0;
                text-decoration: underline;
                padding-top: 20px;
                @include breakpoint(tab-landscape) {
                  padding-top: 0;
                  align-items: baseline;
                }
              }
            }
          }
        }
        .shipping-alert__close-icon {
          top: 25px;
          @include breakpoint(tab-landscape) {
            top: 15px;
          }
        }
      }
      .address-choice {
        margin-top: 30px !important;
        .address-choice__title {
          padding-bottom: 10px;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
        }
        .radio-button__label {
          padding-left: 10px;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.4px;
        }
        &:first-child {
          padding-bottom: 20px;
          border-bottom: 1px solid $color-light-gray;
        }
        &:last-child {
          margin-top: 20px !important;
        }
      }
    }
  }
  &__shipping-modal {
    .modal-popup-body {
      @include breakpoint(desktop-small) {
        width: 880px !important;
        padding: 55px 50px 50px !important;
      }
      @include breakpoint(mobile-landscape) {
        padding: 45px 32px 50px !important;
      }
      @include breakpoint(mobile-potrait) {
        padding: 45px 18px 50px !important;
      }
      .modal-address-instruction {
        @include breakpoint(mobile-potrait) {
          margin-top: 0 !important;
        }
      }
      .close-button {
        top: 25px;
        right: 15px;
        @include breakpoint(tab-landscape) {
          top: 15px;
        }
      }
      .edit-address__title {
        @include text-styles($font-bold, 1.25rem, normal, 0.1136rem);
        text-transform: uppercase;
        color: $slate;
        margin-top: 0;
      }
      .modal-address-instruction {
        @include breakpoint(tab-landscape) {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
        }
        @include breakpoint(mobile-potrait) {
          margin-top: 10px;
        }
      }
    }
  }
  .shipping-address {
    &__shipping-title {
      line-height: normal !important;
    }
    .instruction {
      max-width: 516px;
    }
    .shipping-form {
      div.row {
        padding: 0;
      }
    }
    .shipping-form__submit-button {
      button.kf-react-button {
        margin-top: 0;
      }
    }
    &__checkout-fast-cta {
      color: $slate;
    }
  }
  .edit-address__title {
    @include text-styles($font-bold, 1.25rem, normal, 0.1136rem);
    text-transform: uppercase;
    color: $slate;
    margin-top: 0;
    padding-top: 30px;
  }
  .step-nav__content {
    .step-nav__review-list li {
      color: $slate;
    }
  }
}

.shipping-alert {
  &__modal {
    .shipping-alert__title {
      @include text-styles(
        $font-bold!important,
        1.25rem!important,
        1.5rem!important,
        0.1136rem!important
      );
      text-transform: uppercase;
    }
  }
}
